/*!
 * TagSelector
 */
.tagselector-tag {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  color: #637280;
  cursor: pointer;
  display: inline-block;
  margin: 5px 5px 5px 0;
  padding: 2px 5px;
}
.tagselector-tag.active {
  background: #545969;
  border: 1px solid #545969;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
}