[data-scroll-button] {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  color: var(--bs-primary);
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
}